import React, {useRef, useEffect} from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { BLOCKS } from '@contentful/rich-text-types'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Features from '../../components/Features/Features'
import Button from '../../components/Button/Button'
import Service from '../../components/Service/Service'
import Layout from '../../layout/Layout'
import  { gsap, Power3 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import * as styles from './home.module.scss'

gsap.registerPlugin(ScrollTrigger)

export default function RootIndex() {

  const allHomePageEntry = useStaticQuery(getHomePageQuery)
  const { aboutUs, homePageFeatures, ourSmarts, headerTitle, metaTitle } = allHomePageEntry.contentfulHomePage

  //Rich Text Content & Option
  const ourSmartsRichText = ourSmarts
  const optionService = {
    renderNode: {
      // disable Heading and Paragraph
      [BLOCKS.HEADING_2] : children => null,
      [BLOCKS.PARAGRAPH] : children => null,

      // Render Service Component
      [BLOCKS.EMBEDDED_ENTRY] : ( {data} ) => {
        const titleObj = data.target.title
        const imageUrl = data.target.image.file.url
        const description = data.target.description

        return <Service 
                  { ...titleObj } 
                  description = { description }
                  imageUrl = { imageUrl }
                />
      }
    }
  }

  /* Gsap animation */
  let homeSection = useRef(null)

  useEffect( ()=>{
      gsap.from(homeSection,{
        scrollTrigger:{
            trigger: homeSection,
            start: 'top 90%',
            end: 'bottom 70%',
            toggleActions: 'play none none none',
        },
        opacity: 0,
        y:100,
        duration:1.5,
        ease:Power3.easeInOut,
    })
  },[])

    return (
      <>
          <Layout
            homeHero
            title = {headerTitle || "Does Your Business Think Smart When It Comes to IT?"}
			metaTitle = { metaTitle }
            >

            <section className = {styles.homeSection}
                     ref = { el => { homeSection = el }}>

              <h2 className = {styles.aboutUs}> {aboutUs}  </h2>
              
              <div className = {styles.features}>
                <h3> {homePageFeatures.title} </h3>
                <Features />
              </div>

            </section>

            <section className = {styles.ourSmarts}>

              <div className = {styles.titleContainer}>
              
                <div>
                    {renderRichText(ourSmartsRichText)}
                </div>
                <Button slug = "/services" pink> VIEW ALL SERVICES </Button>
              
              </div>
                
              <div className = {styles.services}>
                  {renderRichText(ourSmartsRichText,optionService)}
              </div>
                
            </section>

          </Layout>
      </>
    )
}


const getHomePageQuery = graphql`
  query HomePage {
    contentfulHomePage {
		headerTitle
		metaTitle
        aboutUs
        homePageFeatures {
          title
        }
        ourSmarts {
          raw
          references {
            contentful_id
            description
            image {
              file {
                url
              }
            }
            title {
              title
              slug
            }
          }
        }
      }
  
  }   
`